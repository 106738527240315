var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wIJ3WpxkpSnfW9dXxKlzg"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import { sentryDns } from './src/env';

Sentry.init({
  dsn: sentryDns,
  tracesSampleRate: 0.2,
  integrations: [new BrowserTracing()],
});
